import React from "react";

const Button = ({ bgColor, color, textSize, text, borderRadius, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`${textSize} text-${color} bg-${bgColor} font-network p-3 border-${bgColor} hover:bg-black hover:text-${bgColor}`}
    >
      {text}
    </button>
  );
};

export default Button;
