import React from "react";
import { MdOutlineCancel } from "react-icons/md";

import { Button } from ".";
import { userProfileData } from "../data/dummy";
import avatar from "../data/user.png";
import { useStatContext } from "../contexts/ContextProvider";
import { toSectionedToken } from "../utils/Helper";

const UserProfile = () => {
  const { currentColor, handleNotClick, logout, messages } = useStatContext();

  let username = localStorage.getItem("username");
  let sectionedUsername = username;
  return (
    <div className="nav-item absolute end-1 border-1 border-[#1ffea9] bg-black top-16 dark:bg-[#42464D] w-48 sm:w-96">
      <div className="relative flex flex-col p-5">
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <div className="flex justify-between items-center">
          <p className="font-semibold text-sm sm:text-lg text-[#1ffea9] dark:text-gray-200">
            {messages["profile"]}
          </p>
          <Button
            text={<MdOutlineCancel />}
            color="red-500"
            bgHoverColor="light-gray"
            onClick={() => handleNotClick("userProfile")}
            textSize="text-sm sm:text-2xl"
            borderRadius="50%"
          />
        </div>
        <div
          className="flex gap-5 items-center
         mt-6 border-color border-b-1 pb-6"
        >
          <img
            className="h-10
             w-10
            sm:h-24
            sm:w-24
            p-2
             rounded-md"
            src={avatar}
            alt="user-profile"
          />
          <div dir="ltr">
            <p
              className="font-semibold text-white
             text-[14px] sm:text-xl dark:text-gray-200"
            >
              {" "}
              {messages.user}{" "}
            </p>
            <p className="text-white text-[6px] sm:text-lg font-semibold dark:text-gray-400 font-network">
              {" "}
              {sectionedUsername}{" "}
            </p>
          </div>
        </div>
        <div className="mt-5">
          <button
            onClick={() => {
              handleNotClick("userProfile");
              logout();
            }}
            className="cybr-btn"
            style={{ "--primary-hue": 10 }}
          >
            {messages.logout}
            <span aria-hidden className="cybr-btn__glitch">
              {messages.logout}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
