import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import EnTranslations from "../translations/en.json";
import FaTranslations from "../translations/fa.json";
import { toUnSectionedToken } from "../utils/Helper";

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};

function getMessages(locale) {
  if (locale === "en") {
    return EnTranslations;
  } else if (locale === "fa") {
    return FaTranslations;
  }
  return EnTranslations;
}

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState("#03C9D7");
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [toast, setToast] = useState({ isToasted: false, text: "", color: "" });
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const [locale, setLocale] = useState(localStorage.getItem("locale") || "en"); // state for current locale

  const navigate = useNavigate();

  const handleLanguageChange = (selectedLocale) => {
    setLocale(selectedLocale);
    localStorage.setItem("locale", selectedLocale);
  };

  const messages = getMessages(locale);
  const logout = () => {
    localStorage.setItem("site", "");
    localStorage.setItem("username", "");
    setToken("");
    setUser("");
    navigate("/");
  };

  const fetchOverview = async () => {
    try {
      const serviceUrl = "https://adminv3.greennetapp.com/";
      const token = localStorage.getItem("token");

      const response = await fetch(serviceUrl + "netstat/overal", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      });

      const res = await response.json();

      if (res.stat) {
        const newDownTrafficVal = (parseFloat(res.stat.RX) / 1000).toFixed(2);
        const newUpTrafficVal = (parseFloat(res.stat.TX) / 1000).toFixed(2);
        return { ...res.stat, RX: newDownTrafficVal, TX: newUpTrafficVal };
      }
      throw new Error("something went wrong.");
    } catch (err) {
      return err;
    }
  };

  const fetchServersLoads = async () => {
    try {
      const serviceUrl = "https://adminv3.greennetapp.com/";
      const token = localStorage.getItem("token");

      const response = await fetch(serviceUrl + "netstat/loads", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Cache-Control": "no-cache",
          Authorization: "Bearer " + token,
        },
      });

      const res = await response.json();
      if (res.servers) {
        return res.servers;
      }
      throw new Error("something went wrong.");
    } catch (err) {
      return err;
    }
  };

  const loginAction = async (data) => {
    try {
      // data.username = toUnSectionedToken(data.username);
      const serviceUrl = "https://userzoneapi.greennetapp.com/";
      // const serviceUrl = "http://localhost:1443/";
      const response = await fetch(serviceUrl + "auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.Message === "OK") {
        setUser(data.username);
        setToken(res.Obj);
        localStorage.setItem("username", data.username);
        localStorage.setItem("site", res.Obj);
        navigate("/");
        return;
      }
      throw new Error(res.Message);
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const setMode = (e) => {
    setCurrentMode(e.target.value);

    localStorage.setItem("themeMode", e.target.value);

    setThemeSettings(false);
  };

  const setColor = (color) => {
    setCurrentColor(color);

    localStorage.setItem("colorMode", color);

    setThemeSettings(false);
  };
  const handleClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: true });
  };
  const handleNotClick = (clicked) => {
    setIsClicked({ ...initialState, [clicked]: false });
  };
  const setCurrentToast = (toast) => {
    setToast(toast);
    setTimeout(() => {
      setToast({ isToasted: false, text: "", color: "" });
    }, 3000);
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        isClicked,
        setIsClicked,
        handleClick,
        handleNotClick,
        screenSize,
        setScreenSize,
        currentColor,
        currentMode,
        themeSettings,
        setThemeSettings,
        setMode,
        setColor,
        toast,
        setCurrentToast,
        token,
        user,
        loginAction,
        fetchOverview,
        fetchServersLoads,
        logout,
        locale,
        setLocale,
        messages,
        handleLanguageChange,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStatContext = () => useContext(StateContext);
