import { Tick } from "@syncfusion/ej2/circulargauge";
import React, { useContext } from "react";
import { BiDollar, BiLeftArrow, BiRocket, BiStar } from "react-icons/bi";
import {
  BsArrow90DegDown,
  BsArrowBarDown,
  BsClock,
  BsCursor,
  BsCursorFill,
  BsPercent,
} from "react-icons/bs";
import { FiStar } from "react-icons/fi";
import { GrCursor, GrRadialSelected, GrVirtualMachine } from "react-icons/gr";
import { HiHand } from "react-icons/hi";
import { RiStarFill } from "react-icons/ri";
import { SiSpeedtest, SiTarget } from "react-icons/si";
import { TiLockClosed, TiTick } from "react-icons/ti";
import { useStatContext } from "../contexts/ContextProvider";

const PlanCard = ({ duration, clicked, devices, price, off, selected }) => {
  const { messages } = useStatContext();

  price = price + "";
  let priceItems = price.split(",");
  let oldPrice = "";
  let newPrice = "";

  let durationItems = duration.split(",");
  let devicesItems = devices.split(",");

  if (durationItems.length === 2) {
    duration = durationItems[1];
  }

  if (devicesItems.length === 2) {
    devices = devicesItems[1];
  }

  if (priceItems.length === 2) {
    price = priceItems[0];
    oldPrice = priceItems[1];
  }
  return (
    <div
      onClick={clicked}
      className="relative
      h-[120px] w-[120px]
       sm:h-40 sm:w-40
                   flex flex-col gap-2 text-white
                   border-1 border-[#1ffea9] p-3
                   hover:bg-radial-gradient
                    hover:cursor-pointer"
    >
      <div className="absolute border-t-1 border-s-1 border-white h-1 w-1 start-2 top-2"></div>

      {selected ? (
        <div
          className="absolute w-4 h-3
          sm:w-8 sm:h-6
        flex justify-center items-center
         bg-[#1ffea9]
         top-0 end-0"
        >
          <RiStarFill className="text-black text-[8px] sm:text-lg" />
        </div>
      ) : (
        <div className="absolute border-t-1 border-e-1 border-white h-1 w-1 end-2 top-2"></div>
      )}

      <div className="absolute border-b-1 border-s-1 border-white h-1 w-1 start-2 bottom-2"></div>
      <div className="absolute border-b-1 border-e-1 border-white h-1 w-1 end-2 bottom-2"></div>
      <div className="flex items-center text-[9px] sm:text-[12px]">
        <BsClock className="text-[#1ffea9]" />
        <p className="text-white ms-1">
          {duration} {messages.month}
        </p>
      </div>
      <div className="flex items-center text-[9px] sm:text-[12px]">
        <GrVirtualMachine className="text-[#1ffea9]" />
        <p className="text-white ms-1">
          {devices} {messages.device}
        </p>
      </div>
      {off && off !== 0 && (
        <div className="flex items-center text-[9px] sm:text-[12px]">
          <BsPercent className="text-[#1ffea9]" />
          <p className="text-white ms-1">
            {off} {messages.off}
          </p>
        </div>
      )}

      <div className="absolute flex flex-col bottom-2 start-2  text-[9px] sm:text-[12px]">
        {oldPrice !== "" && (
          <div className="relative flex items-center line">
            <BiDollar className="text-[#1ffea9]" />
            <p className="text-white ms-1 line-through">{oldPrice}</p>
            <div className=" absolute h-[1.5px] w-full bg-red-500 -rotate-12"></div>
          </div>
        )}

        <div className="flex items-center  text-sm sm:text-xl">
          <BiDollar className="text-[#1ffea9]" />
          <p className="text-white ms-1">{price}</p>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
