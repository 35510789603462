import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import { GrMemory, GrStatusInfo, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import {
  fetchUserCryptoTransactions,
  fetchUserRialTransactions,
  fetchUserTransactions,
} from "../utils/HttpUtils";

const Transactions = () => {
  const { currentColor, fetchOverview, messages, setCurrentToast } =
    useStatContext();

  const [transactionsMode, setTransactionsMode] = useState("crypto");
  const [rialTransactions, setRialTransactions] = useState(undefined);
  const [cryptoTransactions, setCryptoTransactions] = useState(undefined);

  const fetchUserRialTransactionsFunc = async () => {
    try {
      const result = await fetchUserRialTransactions();
      setRialTransactions((prev) => result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchUserCryptoTransactionsFunc = async () => {
    try {
      const result = await fetchUserCryptoTransactions();
      setCryptoTransactions((prev) => result);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchRequirements = async () => {
    fetchUserRialTransactionsFunc();
    fetchUserCryptoTransactionsFunc();
  };

  useEffect(() => {
    fetchRequirements();
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  return (
    <div
      className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category={messages.financial} title={messages.transactions} />
      {cryptoTransactions && rialTransactions ? (
        <div
          className="flex flex-col gap-2 justify-start
      my-5 mx-4 p-1"
        >
          <div className="flex justify-start">
            <button
              onClick={() => {
                setTransactionsMode("crypto");
              }}
              className={`${
                transactionsMode === "crypto"
                  ? "bg-[#1ffea9]"
                  : "bg-black text-[#1ffea9]"
              } border-1 border-[#1ffea9]
           p-2 hover:bg-[#1ffea9] hover:text-black`}
            >
              {messages.transactions_crypto_button}
            </button>
            <button
              onClick={() => {
                setTransactionsMode("rials");
              }}
              className={`${
                transactionsMode === "rials"
                  ? "bg-[#1ffea9]"
                  : "bg-black text-[#1ffea9]"
              } border-1 border-[#1ffea9]
            p-2 hover:bg-[#1ffea9] hover:text-black`}
            >
              {messages.transactions_rials_button}
            </button>
          </div>
          {transactionsMode === "crypto" ? (
            <>
              {cryptoTransactions && cryptoTransactions.length == 0 ? (
                <div className="flex justify-center items-center">
                  <p
                    className="text-[#1ffea9] bg-radial-gradient
                           border-1 border-[#1ffea9] px-4 py-2
                           text-[10px]  sm:text-sm"
                  >
                    {messages.no_crypto_transactions}
                  </p>
                </div>
              ) : (
                <table>
                  <thead className="border-1 text-[8px]  sm:text-sm border-[#1ffea9] text-[#1ffea9]">
                    <tr>
                      <th>{messages.amount}</th>
                      <th>{messages.transaction_identifier}</th>
                      <th>{messages.time}</th>
                      <th>{messages.status}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cryptoTransactions &&
                      cryptoTransactions.map((cryptoTransaction, cti) => {
                        let statusBg = "";
                        let statusText = "";
                        if (cryptoTransaction.status === 3) {
                          statusBg = "bg-yellow-300";
                          statusText = messages.in_pending;
                        }
                        if (cryptoTransaction.status === 2) {
                          statusBg = "bg-red-500";
                          statusText = messages.rejected;
                        }
                        if (cryptoTransaction.status === 1) {
                          statusBg = "bg-[#1ffea9]";
                          statusText = messages.success;
                        }
                        return (
                          <tr
                            key={cti}
                            className="border-1 text-[8px]  sm:text-sm border-white text-white"
                          >
                            <td className="text-center py-2 font-network">
                              {cryptoTransaction.amount} $
                            </td>
                            <td className="text-center py-2">
                              {cryptoTransaction.crypto_transaction_id}
                            </td>
                            <td className="text-center py-2">
                              {cryptoTransaction.tcreated
                                .slice(0, 19)
                                .replace("T", " ")}
                            </td>
                            <td
                              className={`text-center ${statusBg} 
            text-black py-2
            border-b-1 border-black`}
                            >
                              {statusText}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
            </>
          ) : (
            <>
              {rialTransactions && rialTransactions.length == 0 ? (
                <div className="flex justify-center items-center">
                  <p
                    className="text-[#1ffea9] bg-radial-gradient
                           border-1 border-[#1ffea9] px-4 py-2
                           text-[10px]  sm:text-sm"
                  >
                    {messages.no_rials_transactions}
                  </p>
                </div>
              ) : (
                <table>
                  <thead>
                    <tr className="border-1 text-[8px]  sm:text-sm border-[#1ffea9] text-[#1ffea9]">
                      <th>{messages.amount}</th>
                      <th>{messages.last_4_digits}</th>
                      <th>{messages.transaction_id}</th>
                      <th>{messages.time}</th>
                      <th>{messages.status}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rialTransactions.map((rialtransaction, rialindex) => {
                      let statusBg = "";
                      let statusText = "";
                      if (rialtransaction.status === 3) {
                        statusBg = "bg-yellow-300";
                        statusText = messages.in_pending;
                      }
                      if (rialtransaction.status === 2) {
                        statusBg = "bg-red-500";
                        statusText = messages.rejected;
                      }
                      if (rialtransaction.status === 1) {
                        statusBg = "bg-[#1ffea9]";
                        statusText = messages.success;
                      }
                      return (
                        <tr
                          key={rialindex}
                          className="border-1 border-white text-[8px]  sm:text-sm text-white"
                        >
                          <td className="text-center py-2 font-network">
                            {rialtransaction.amount.toFixed(2)} $
                          </td>
                          <td className="text-center py-2">
                            {rialtransaction.last_digits}
                          </td>
                          <td className="text-center py-2">
                            {rialtransaction.rial_transaction_id}
                          </td>
                          <td className="text-center py-2">
                            {rialtransaction.tcreated
                              .slice(0, 19)
                              .replace("T", " ")}
                          </td>
                          <td
                            className={`text-center ${statusBg} text-black py-2  border-b-1 border-black`}
                          >
                            {statusText}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </>
          )}
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
};

export default Transactions;
