const apiUrl = "https://userzoneapi.greennetapp.com/";
// const apiUrl = "http://localhost:1443/";

// UserZone Panel
export const fetchToken = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteUserDevice = async (userId) => {
  try {
    const data = {
      user_id: userId,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "account/delete-device", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchManager = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/manager", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchManagers = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/managers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchMoneyUnit = async (moneyUnitId) => {
  try {
    const data = {
      unit: moneyUnitId,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/money-units", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchUserBalance = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/balance", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const makeTransaction = async (data) => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "payment/pay", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchUserRialTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/user-rial-transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchUserCryptoTransactions = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/user-crypto-transactions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const changeUserPassword = async (currentPassword, newPassword) => {
  try {
    let data = {
      current_password: currentPassword,
      password: newPassword,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "account/change-user-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Message;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const fetchPlans = async () => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/plans", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const orderPlan = async (planId) => {
  try {
    let data = {
      plan_id: planId,
    };
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "account/order", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const unpaidPayment = async (planId) => {
  try {
    const token = localStorage.getItem("site");
    const response = await fetch(apiUrl + "info/unpaid-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + token,
      },
    });

    if (response.ok) {
      const res = await response.json();
      return res.Obj;
    }
    const errBody = await response.json();
    throw new Error(errBody.Message);
  } catch (err) {
    throw new Error(err.message);
  }
};
