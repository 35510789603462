import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import { GrMemory, GrStatusInfo, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import { changeUserPassword } from "../utils/HttpUtils";

const Settings = () => {
  const { currentColor, fetchOverview, messages, setCurrentToast } =
    useStatContext();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const [modalPrimaryButtonProgressing, setModalPrimaryButtonProgressing] =
    useState(false);

  let permitSaveBtn = false;
  if (currentPassword !== "" && newPassword !== "") {
    permitSaveBtn = true;
  }

  return (
    <div
      className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
    >
      <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
      <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
      <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
      <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
      <Header category={messages.general} title={messages.settings} />
      <div
        className="flex flex-col gap-2 justify-start
      my-6 mx-4"
      >
        <h1 className="border-b-1 text-white">{messages.change_password}</h1>
        <div className="flex flex-col justify-start gap-5 w-2/3">
          <div className="flex justify-between items-center flex-wrap">
            <span className=" text-white font-semibold">
              {messages.current_password}:
            </span>
            <input
              onChange={(e) => {
                let currentPassword = "";
                currentPassword = e.target.value;
                setCurrentPassword(currentPassword);
              }}
              className="ms-2 p-1 "
              placeholder={messages.enter_current_password}
            />
          </div>
          <div className="flex justify-between items-center flex-wrap">
            <span className=" text-white font-semibold">
              {messages.new_password}:
            </span>
            <input
              onChange={(e) => {
                let newPassword = "";
                newPassword = e.target.value;
                setNewPassword(newPassword);
              }}
              className="ms-2 p-1 "
              placeholder={messages.enter_new_password}
            />
          </div>
        </div>
        <button
          disabled={!permitSaveBtn}
          onClick={async () => {
            try {
              setModalPrimaryButtonProgressing(true);
              let result = await changeUserPassword(
                currentPassword,
                newPassword
              );
              setModalPrimaryButtonProgressing(false);
            } catch (error) {
              setCurrentToast({
                isToasted: true,
                text: error.message,
                color: "red-500",
              });
              setModalPrimaryButtonProgressing(false);
            }
          }}
          className={`self-end flex justify-center items-center p-2 border-1
          ${
            permitSaveBtn
              ? "bg-[#1ffea9] border-[#1ffea9] hover:bg-black hover:border-[#1ffea9] hover:text-[#1ffea9]"
              : "bg-[#82c0a8] border-[#82c0a8]"
          }
         `}
        >
          {modalPrimaryButtonProgressing && (
            <div
              className="loader-transparent me-2"
              style={{ height: "20px", width: "20px" }}
            ></div>
          )}
          {messages.save}
        </button>
      </div>
    </div>
  );
};

export default Settings;
