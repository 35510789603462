import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import {
  GrAdd,
  GrConfigure,
  GrMemory,
  GrStatusInfo,
  GrStorage,
} from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdAccountBalance, MdBalance, MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import Modal from "../components/Modal";
import { GiPayMoney } from "react-icons/gi";
import {
  fetchManager,
  fetchManagers,
  fetchMoneyUnit,
  fetchUserBalance,
  makeTransaction,
} from "../utils/HttpUtils";
import { isEmpty } from "../utils/Helper";

const Wallet = () => {
  const { fetchOverview, messages, setCurrentToast } = useStatContext();
  const [progressing, setProgressing] = useState(false);
  const [increaseModalOpen, setIncreaseModalOpen] = useState(false);
  const [paymentMode, setPaymentMode] = useState("crypto");
  const [manager, setManager] = useState({});

  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState({});

  const [dollarUnitPrice, setDollarUnitPrice] = useState({});
  const [balance, setBalance] = useState({});

  const [modalPrimaryButtonProgressing, setModalPrimaryButtonProgressing] =
    useState(false);

  const [cryptoAmount, setCryptoAmount] = useState(0.0);
  const [cryptoTransactionId, setCryptoTransactionId] = useState("");
  const [cryptoMethod, setCryptoMehtod] = useState("");

  const [rialAmount, setRialAmount] = useState(0.0);
  const [dollarAmount, setDollarAmount] = useState(0.0);
  const [rialTransactionId, setRialTransactionId] = useState("");
  const [rialLast4Digits, setRialLast4Digits] = useState("");

  const clearTransactionValues = async () => {
    setPaymentMode("crypto");
    setManager({});

    setManagers([]);
    setSelectedManager({});

    setDollarAmount({});
    setBalance({});
    setCryptoAmount(0.0);
    setCryptoTransactionId("");
    setCryptoMehtod("");
    setRialAmount(0.0);
    setDollarAmount(0.0);
    setRialTransactionId("");
    setRialLast4Digits("");
  };

  const fetchManagerFunc = async () => {
    try {
      const manager = await fetchManager();
      setManager((prev) => manager);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchManagersFunc = async () => {
    try {
      const managers = await fetchManagers();
      let rialManagers = managers.filter((m, i) => m.role === 2);
      setManagers((prev) => rialManagers);
      setSelectedManager(rialManagers[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDollarPrice = async () => {
    try {
      const dollarUnit = await fetchMoneyUnit(1);
      setDollarUnitPrice((prev) => dollarUnit);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserBalanceFunc = async () => {
    try {
      const balance = await fetchUserBalance();
      setBalance(balance);
      // setDollarUnitPrice((prev) => dollarUnit);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRequirements = () => {
    fetchUserBalanceFunc();
    fetchManagerFunc();
    // fetchManagersFunc();
    fetchDollarPrice();
  };

  useEffect(() => {
    fetchRequirements();
    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  let creditCardNumber = "";
  let bank = "";
  let nameAndLastname = "";
  if (!isEmpty(manager)) {
    let items = manager.credit_card_info.split(",");
    creditCardNumber = items[0];
    bank = items[1];
    nameAndLastname = items[2];
    creditCardNumber = creditCardNumber.replaceAll("-", " - ");
  }

  let rialToDollar = 0.0;
  if (rialAmount !== 0.0) {
    rialToDollar = rialAmount / (dollarUnitPrice.rials / 10);
  }

  let requestedPaymentMode = paymentMode === "crypto" ? 1 : 2;
  let permitSubmit = false;

  if (requestedPaymentMode === 1) {
    if (
      cryptoAmount !== 0.0 &&
      !isNaN(cryptoAmount) &&
      cryptoTransactionId !== ""
    ) {
      permitSubmit = true;
    }
  }

  if (requestedPaymentMode === 2) {
    if (
      rialAmount !== 0.0 &&
      (rialTransactionId !== "" || rialLast4Digits !== "")
    ) {
      permitSubmit = true;
    }
  }

  return (
    <>
      <div
        className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>

        <Header category={messages.financial} title={messages.wallet} />
        {!isEmpty(manager) && !isEmpty(dollarUnitPrice) && !isEmpty(balance) ? (
          <div
            className="flex flex-col gap-2 justify-start
      my-5 mx-4"
          >
            <div className="flex flex-col justify-start gap-5">
              <div
                className="flex justify-between 
              flex-wrap gap-5"
              >
                <div className="flex items-center">
                  <MdAccountBalance className="text-[#1ffea9] text-[14px]  sm:text-3xl" />
                  <span
                    className="ms-2 text-white 
                  text-[14px]  sm:text-3xl
                  font-semibold"
                  >
                    {messages.balance}:
                  </span>
                  <span
                    className="ms-2 text-white 
                  text-[14px]  sm:text-3xl"
                  >
                    {balance.amount.toFixed(2)} $
                  </span>
                </div>
                <button
                  className="flex border-1 border-[#1ffea9] items-center
             bg-[#1ffea9] text-black
             text-[14px]  sm:text-lg
             p-2 hover:bg-black hover:border-[#1ffea9] hover:text-[#1ffea9]"
                  onClick={() => {
                    setProgressing(false);
                    setIncreaseModalOpen(true);
                  }}
                >
                  <GrAdd />
                  <span className="ms-2">{messages.increase}</span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <Modal
        open={increaseModalOpen}
        onClose={() => {
          setIncreaseModalOpen(false);
          setProgressing(false);
        }}
      >
        {progressing ? (
          <div className="p-24">
            <p className="text-[#1ffea9]">Loading...</p>
          </div>
        ) : (
          <div className="text-center px-8">
            <GiPayMoney className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-2 text-[#1ffea9]">
                {messages.payment}
              </h3>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="flex flex-col gap-2">
                <p className="text-[#1ffea9]">{messages.pay_as}</p>
                <div className="flex items-center gap-5 text-[0.7rem] sm:text-sm">
                  <button
                    className={`text-white pb-1 ${
                      paymentMode === "crypto" ? "border-b-2" : ""
                    } hover:cursor-pointer
                  hover:scale-110`}
                    onClick={() => {
                      setPaymentMode("crypto");
                    }}
                  >
                    {messages.crypto}
                  </button>
                  <button
                    className={`text-white ${
                      paymentMode === "rials" ? "border-b-2" : ""
                    } pb-1 hover:cursor-pointer
                   hover:scale-110`}
                    onClick={() => {
                      setPaymentMode("rials");
                    }}
                  >
                    {messages.rials}
                  </button>
                </div>
              </div>
              {paymentMode === "crypto" ? (
                <div className="flex flex-col items-center">
                  <div className="flex flex-col gap-2 ">
                    <p className="text-[#1ffea9] text-[10px] sm:text-[12px]">
                      {messages.wallet_address_usdt}
                    </p>
                    <div className="flex items-center flex-wrap">
                      <span className="text-[#1ffea9] text-[8px] sm:text-[12px]">
                        TRC20 :
                      </span>
                      <p className="ms-2 text-white text-[8px] sm:text-[12px]">
                        TXFEqgg6gjsDhnN2bcgGjQEuVmnbP6km2E
                      </p>
                    </div>

                    <p className="text-[#1ffea9] text-[10px] sm:text-[12px]">
                      {messages.transaction_info}
                    </p>
                    <div className="flex flex-col items-start gap-1">
                      <span className="text-sm text-[#1ffea9] text-[0.7rem] sm:text-[12px]">
                        {messages.amount}:
                      </span>
                      <input
                        value={cryptoAmount}
                        type="number"
                        step={"0.01"}
                        onChange={(e) => {
                          let stringValue = e.target.value;
                          let cryptoAmountFloat = parseFloat(stringValue);
                          setCryptoAmount(cryptoAmountFloat);
                        }}
                        className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:border-[#1ffea9]
                       text-[0.7rem] sm:text-[12px]
                       focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-1">
                      <span className="text-sm text-[#1ffea9] text-[0.7rem] sm:text-[12px]">
                        {messages.network}:
                      </span>
                      <input
                        value={cryptoMethod}
                        onChange={(e) => {
                          let stringValue = e.target.value;
                          setCryptoMehtod(stringValue);
                        }}
                        className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                       focus:border-[#1ffea9]
                       text-[0.7rem] sm:text-[12px]
                       focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-1 mt-8">
                      <span className="text-sm text-[#1ffea9] text-[0.7rem] sm:text-[12px]">
                        {messages.transaction_identifier}:
                      </span>
                      <input
                        value={cryptoTransactionId}
                        onChange={(e) => {
                          setCryptoTransactionId(e.target.value);
                        }}
                        className="p-1 bg-black border-1 
                    border-[#1ffea9] text-[#1ffea9]
                    text-[0.7rem] sm:text-[12px]
                     focus:outline-none"
                      />
                      <p className="text-white text-[10px] place-content-baseline">
                        <span className="text-red-500 text-[0.7rem] sm:text-[12px] align-middle">
                          *
                        </span>{" "}
                        {messages.crypto_fill_warning}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <div
                    className="flex justify-center 
                    p-2
                    scrollbar scrollbar-medium scrollbar-thumb-[#1ffea9] scrollbar-thumb-rounded
                  items-center gap-2 w-48 overflow-x-scroll"
                  >
                    <div
                      className={`flex flex-col
                          flex-shrink-0 
                          gap-2 font-vazir
                       text-white
                        p-2`}
                    >
                      <p dir="ltr" className="text-[0.7rem] sm:text-[11px]">
                        {creditCardNumber}
                      </p>
                      <p className="text-[0.7rem] sm:text-[11px]">{bank}</p>
                      <p className="text-[0.7rem] sm:text-[11px]">
                        {nameAndLastname}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 ">
                    <p className="text-[#1ffea9]">
                      {messages.transaction_info}
                    </p>
                    <div className="flex flex-col items-start gap-1">
                      <span
                        className="text-sm
                       text-[#1ffea9] text-[0.7rem] sm:text-[12px]"
                      >
                        {messages.amount_rials}:
                      </span>
                      <input
                        value={rialAmount}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={(e) => {
                          let stringValue = e.target.value;
                          if (stringValue === "") {
                            setRialAmount(0);
                            return;
                          }
                          let rialsAmountInt = parseInt(stringValue);
                          setRialAmount(rialsAmountInt);
                        }}
                        className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                      text-[0.7rem] sm:text-[12px]
                       focus:border-[#1ffea9]
                       focus:outline-none"
                      />
                    </div>
                    <div className="flex">
                      <p
                        className="text-[#1ffea9]
                      text-[0.6rem] sm:text-[12px]"
                      >
                        {" "}
                        {messages.payment_rials_to_dollars} :
                      </p>
                      <p
                        className="text-white ms-2
                      text-[0.6rem] sm:text-[12px]"
                      >
                        {rialToDollar.toFixed(2)} $
                      </p>
                    </div>
                    <div className="flex flex-col items-start gap-1 mt-4">
                      <span
                        className="text-sm text-[#1ffea9]
                      text-[0.7rem] sm:text-[12px]"
                      >
                        {messages.transaction_id}:
                      </span>
                      <input
                        value={rialTransactionId}
                        onChange={(e) => {
                          setRialTransactionId(e.target.value);
                        }}
                        className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                      text-[0.7rem] sm:text-[12px]
                       focus:border-[#1ffea9]
                       focus:outline-none"
                      />
                    </div>
                    <div className="flex flex-col items-start gap-1">
                      <span
                        className="text-sm text-[#1ffea9]
                      text-[0.7rem] sm:text-[12px]"
                      >
                        {messages.last_4_digits}:
                      </span>
                      <input
                        value={rialLast4Digits}
                        onChange={(e) => {
                          setRialLast4Digits(e.target.value);
                        }}
                        className="p-1 bg-black border-1 
                      border-[#1ffea9] text-[#1ffea9]
                      text-[0.7rem] sm:text-[12px]
                       focus:outline-none"
                      />
                      <p className="text-white text-[10px] place-content-baseline">
                        <span
                          className="text-red-500
                        text-[0.7rem] sm:text-[12px]
                         text-2xl align-middle"
                        >
                          *
                        </span>{" "}
                        {messages.rials_fill_warning}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="flex gap-4 mt-4">
              <button
                disabled={!permitSubmit}
                className={`flex justify-center items-center py-1
                ${permitSubmit ? "bg-[#1ffea9]" : "bg-[#82c0a8]"}
                 text-black w-full`}
                onClick={async () => {
                  try {
                    setModalPrimaryButtonProgressing(true);
                    // const result = await ();
                    let data = {};
                    if (requestedPaymentMode === 1) {
                      data = {
                        mode: requestedPaymentMode,
                        amount: cryptoAmount,
                        method: cryptoMethod,
                        transaction_id: cryptoTransactionId,
                      };
                    } else {
                      data = {
                        mode: requestedPaymentMode,
                        manager_id: manager.id,
                        amount: rialAmount,
                        dollars: rialToDollar,
                        transaction_id: rialTransactionId,
                        last_digits: rialLast4Digits,
                      };
                    }

                    let resultObj = await makeTransaction(data);
                    setModalPrimaryButtonProgressing(false);
                    clearTransactionValues();
                    fetchRequirements();
                  } catch (error) {
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                    clearTransactionValues();
                    fetchRequirements();
                  }

                  setModalPrimaryButtonProgressing(false);
                  setIncreaseModalOpen(false);
                }}
              >
                {modalPrimaryButtonProgressing && (
                  <div
                    className="loader-transparent me-2"
                    style={{ height: "20px", width: "20px" }}
                  ></div>
                )}
                {messages.payment_pay_button}
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  setIncreaseModalOpen(false);
                  setProgressing(false);
                }}
              >
                {messages.close_button}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Wallet;
