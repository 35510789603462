import React, { useEffect, useState } from "react";
import {
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiCalendar,
  BiDollar,
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiRefresh,
  BiUpload,
  BiWindows,
} from "react-icons/bi";
import {
  GrConfigure,
  GrMemory,
  GrStatusInfo,
  GrStorage,
  GrVirtualMachine,
} from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu } from "react-icons/fi";
import { MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import Modal from "../components/Modal";
import PlanCard from "../components/PlanCard";
import {
  fetchPlans,
  fetchToken,
  orderPlan,
  unpaidPayment,
} from "../utils/HttpUtils";
import { isAfter, isEmpty } from "../utils/Helper";

const Overview = () => {
  const { fetchOverview, messages, setCurrentToast } = useStatContext();
  const [progressing, setProgressing] = useState(false);
  const [renewModalOpen, setRenewModalOpen] = useState(false);

  const [token, setToken] = useState({});
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [modalPrimaryButtonProgressing, setModalPrimaryButtonProgressing] =
    useState(false);

  const [hasUnpaidPaymentWithOrder, setHasUnpaidPaymentWithOrder] =
    useState(false);
  const clearReqs = () => {
    setHasUnpaidPaymentWithOrder(false);
    setPlans([]);
    setToken({});
    setSelectedPlan({});
  };

  const fetchUnpaidPaymentFunc = async () => {
    try {
      const unpaid = await unpaidPayment();
      setHasUnpaidPaymentWithOrder((prev) => unpaid);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPlansFunc = async () => {
    try {
      const plans = await fetchPlans();
      setPlans((prev) => plans);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTokenFunc = async () => {
    try {
      const token = await fetchToken();
      setToken((prev) => token);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRequirements = () => {
    fetchUnpaidPaymentFunc();
    fetchTokenFunc();
    fetchPlansFunc();
  };

  useEffect(() => {
    fetchRequirements();
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  let isExpired = false;
  let newTokenStr = "";
  let isFirstUser = false;

  if (!isEmpty(token)) {
    if (token.expire !== "0001-01-01T00:00:00Z") {
      isExpired = isAfter(token.expire);
    }

    if (token.token && token.token.length > 1 && token.token.length !== 19) {
      for (var i = 0; i < token.token.length; i++) {
        if (i != 0 && i % 4 == 0) {
          newTokenStr += "-";
        }
        newTokenStr += token.token.charAt(i);
      }
    } else {
      newTokenStr = token.token;
    }

    if (token.days === 0 && token.device_num === 0) {
      isFirstUser = true;
    }
  }

  let permitSelectButton = !isEmpty(selectedPlan);

  return (
    <>
      <div
        className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <Header category={messages.zone} title={messages.overview} />
        {!isEmpty(token) ? (
          <div
            className="flex flex-col gap-2 justify-start
      my-10 mx-2"
          >
            <h1 className="border-b-1 text-white">
              {messages.token_information}
            </h1>
            <div className="flex flex-col justify-start gap-5 p-5">
              <div
                className="flex items-center 
              text-[10px]  sm:text-[14px]
              gap-1 flex-wrap"
              >
                <TiTicket className="text-[#1ffea9]" />
                <span className="ms-2 text-white font-semibold">
                  {messages.token}:
                </span>
                <span className="ms-2 text-black bg-white font-network">
                  {newTokenStr}
                </span>
              </div>
              <div
                className="flex items-center
              text-[10px]  sm:text-[14px]
               gap-1 flex-wrap"
              >
                <GrStatusInfo className="text-[#1ffea9]" />
                <span className="ms-2 text-white font-semibold">
                  {messages.status}:
                </span>
                <span
                  className={`ms-6 text-black ${
                    isFirstUser
                      ? hasUnpaidPaymentWithOrder
                        ? "bg-yellow-300"
                        : "bg-gray-500"
                      : isExpired
                      ? "bg-red-500"
                      : "bg-[#1ffea9]"
                  } p-1`}
                >
                  {isFirstUser
                    ? hasUnpaidPaymentWithOrder
                      ? messages.user_pending
                      : messages.disabled
                    : isExpired
                    ? messages.expired
                    : messages.active}
                </span>
                {isExpired && (
                  <button
                    className="flex items-center ms-6 
            border-1 border-orange-500
            text-black bg-orange-500 p-1
            hover:bg-black hover:text-orange-500"
                    onClick={() => {
                      // setProgressing(true);
                      setRenewModalOpen(true);
                    }}
                  >
                    <BiRefresh />
                    <span className="ms-1">{messages.renew}</span>
                  </button>
                )}
                {isFirstUser && !hasUnpaidPaymentWithOrder && (
                  <button
                    className="flex items-center ms-6 
            border-1 border-orange-500
            text-black bg-orange-500 p-1
            hover:bg-black hover:text-orange-500"
                    onClick={() => {
                      // setProgressing(true);
                      setRenewModalOpen(true);
                    }}
                  >
                    <BiRefresh />
                    <span className="ms-1">{messages.activate}</span>
                  </button>
                )}
              </div>
              <div
                className="flex items-center gap-1
               text-[10px]  sm:text-[14px]  flex-wrap"
              >
                <GrVirtualMachine className="text-[#1ffea9]" />
                <span className="ms-2 text-white font-semibold">
                  {messages.device_capacity}:
                </span>
                <span className="ms-6 text-white p-1 font-network">
                  {token.device_num}
                </span>
              </div>
              <div
                className="flex items-center 
              text-[10px]  sm:text-[14px]
              gap-1 flex-wrap"
              >
                <BiCalendar className="text-[#1ffea9]" />
                <span className="ms-2 text-white font-semibold">
                  {messages.days_count}:
                </span>
                <span className="ms-6 text-white p-1 font-network">
                  {token.days}
                </span>
              </div>
              <div
                className="flex items-center 
              text-[10px]  sm:text-[14px]
              gap-1 flex-wrap"
              >
                <BiPlay className="text-[#1ffea9]" />
                <span className="ms-2 text-white font-semibold">
                  {messages.start}:
                </span>
                {token.start === "0001-01-01T00:00:00Z" ? (
                  <span className="ms-6 text-black bg-yellow-300 p-1">
                    {messages.not_started}
                  </span>
                ) : (
                  <span className="ms-6 text-white p-1 font-network">
                    {token.start.slice(0, 19).replace("T", " ")}
                  </span>
                )}
              </div>
              {token.expire !== "0001-01-01T00:00:00Z" && (
                <div
                  className="flex items-center 
                text-[10px]  sm:text-[14px]
                gap-1 flex-wrap"
                >
                  <BiPause className="text-[#1ffea9]" />
                  <span className="ms-2 text-white font-semibold">
                    {messages.expire}:
                  </span>
                  <span className="ms-6 text-white p-1 font-network">
                    {token.expire.slice(0, 19).replace("T", " ")}
                  </span>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <Modal
        open={renewModalOpen}
        size={progressing ? null : "w-[80%] sm:w-1/2"}
        onClose={() => {
          setRenewModalOpen(false);
          setSelectedPlan(false);
          setProgressing(false);
        }}
      >
        {progressing ? (
          <div className="p-24">
            <p className="text-[#1ffea9]">Loading...</p>
          </div>
        ) : (
          <div className="text-center">
            <BiRefresh className="mx-auto text-[#1ffea9] text-2xl" />
            <div className="my-4">
              <h3 className="text-lg font-black my-5 text-[#1ffea9]">
                {isFirstUser ? messages.activate : messages.renew}
              </h3>
            </div>
            <div
              className="flex flex-col h-96
             overflow-y-scroll gap-8 items-center
              "
            >
              {plans.filter((p, i) => p.off !== 0).length != 0 && (
                <div className="flex flex-col gap-2">
                  <p className="text-[#1ffea9] self-start">
                    {messages.special}
                  </p>
                  <div className="flex items-start gap-2 xl:gap-6 flex-wrap">
                    {plans
                      .filter((p, i) => p.off !== 0)
                      .map((plan, index) => {
                        return (
                          <PlanCard
                            selected={selectedPlan.id === plan.id}
                            clicked={() => {
                              setSelectedPlan(plan);
                            }}
                            key={index}
                            duration={plan.plan_duration_label}
                            devices={plan.plan_devices_label}
                            off={plan.off}
                            price={plan.plan_price_label}
                          />
                        );
                      })}
                  </div>
                </div>
              )}

              <div className="flex flex-col gap-2">
                <p className="text-[#1ffea9]  self-start">
                  {messages.one_month}
                </p>
                <div className="flex items-start gap-2 xl:gap-6 flex-wrap">
                  {plans
                    .filter((p, i) => p.days === 30)
                    .map((plan, index) => {
                      return (
                        <PlanCard
                          selected={selectedPlan.id === plan.id}
                          clicked={() => {
                            setSelectedPlan(plan);
                          }}
                          key={index}
                          duration={plan.plan_duration_label}
                          devices={plan.plan_devices_label}
                          price={plan.plan_price_label}
                        />
                      );
                    })}
                  {/* <PlanCard duration={"1"} devices={"3"} price={2} />
                  <PlanCard duration={"1 + 2"} devices={"3 + 2"} price={5} />
                  <PlanCard duration={"3"} devices={"6"} price={10} /> */}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#1ffea9]  self-start">
                  {messages.three_months}
                </p>
                <div className="flex items-start gap-2 xl:gap-6 flex-wrap">
                  {plans
                    .filter((p, i) => p.days === 90)
                    .map((plan, index) => {
                      return (
                        <PlanCard
                          selected={selectedPlan.id === plan.id}
                          clicked={() => {
                            setSelectedPlan(plan);
                          }}
                          key={index}
                          duration={plan.plan_duration_label}
                          devices={plan.plan_devices_label}
                          price={plan.plan_price_label}
                        />
                      );
                    })}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <p className="text-[#1ffea9]  self-start">
                  {messages.one_year}
                </p>
                <div className="flex items-start gap-2 xl:gap-6 flex-wrap">
                  {plans
                    .filter((p, i) => p.days === 365)
                    .map((plan, index) => {
                      return (
                        <PlanCard
                          selected={selectedPlan.id === plan.id}
                          clicked={() => {
                            setSelectedPlan(plan);
                          }}
                          key={index}
                          duration={plan.plan_duration_label}
                          devices={plan.plan_devices_label}
                          price={plan.plan_price_label}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-5">
              <div className="flex text-white text-sm">
                <p className="text-[#1ffea9] ">{messages.plan}:</p>
                <p className=" ms-2">
                  {!isEmpty(selectedPlan) &&
                    selectedPlan.plan_duration_label.split(",")[0]}{" "}
                  {messages.month} - {selectedPlan.devices} {messages.device}
                </p>
              </div>
              <div className="flex text-white text-sm">
                <p className="text-[#1ffea9] ">{messages.price}:</p>
                <p className=" ms-2">{selectedPlan.price} $</p>
              </div>
            </div>
            <div className="flex gap-4 mt-4">
              <button
                className={`
                flex justify-center items-center
                ${
                  permitSelectButton
                    ? "bg-[#1ffea9] border-[#1ffea9]"
                    : "bg-[#82c0a8] border-[#82c0a8]"
                }
                py-1 w-full`}
                onClick={async () => {
                  try {
                    setModalPrimaryButtonProgressing(true);
                    const result = await orderPlan(selectedPlan.id);
                    setModalPrimaryButtonProgressing(false);
                  } catch (error) {
                    setModalPrimaryButtonProgressing(false);
                    setCurrentToast({
                      isToasted: true,
                      text: error.message,
                      color: "red-500",
                    });
                  }
                  //reset all values
                  clearReqs();
                  fetchRequirements();
                  setRenewModalOpen(false);
                }}
              >
                {modalPrimaryButtonProgressing && (
                  <div
                    className="loader-transparent me-2"
                    style={{ height: "20px", width: "20px" }}
                  ></div>
                )}
                {messages.buy_plan_button}
              </button>
              <button
                className="bg-red-500 py-1 text-black w-full"
                onClick={() => {
                  setRenewModalOpen(false);
                  setSelectedPlan(false);
                  setProgressing(false);
                }}
              >
                {messages.close_button}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Overview;
