import React, { useEffect, useState } from "react";
import {
  BsAndroid,
  BsApple,
  BsClock,
  BsCpuFill,
  BsCurrencyDollar,
  BsMemory,
  BsTelephoneInboundFill,
  BsWindows,
} from "react-icons/bs";
import { GoDotFill } from "react-icons/go";

import { Stacked, Pie, Button, SparkLine, Header } from "../components";
import {
  earningData,
  SparklineAreaData,
  ecomPieChartData,
} from "../data/dummy";
import { useStatContext } from "../contexts/ContextProvider";
import {
  BiDownload,
  BiMemoryCard,
  BiPause,
  BiPlay,
  BiUpload,
} from "react-icons/bi";
import { GrMemory, GrStatusInfo, GrStorage } from "react-icons/gr";
import { SiSpeedtest } from "react-icons/si";
import { FiCpu, FiDelete } from "react-icons/fi";
import { MdDelete, MdMemory } from "react-icons/md";
import { TiTicket } from "react-icons/ti";
import { isEmpty } from "../utils/Helper";
import { deleteUserDevice, fetchToken } from "../utils/HttpUtils";
import Modal from "../components/Modal";

const Devices = () => {
  const { currentColor, fetchOverview, messages, setCurrentToast } =
    useStatContext();
  const [token, setToken] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [questionableAction, setQuestionableAction] = useState(
    () => async () => {}
  );

  const [modalPrimaryButtonProgressing, setModalPrimaryButtonProgressing] =
    useState(false);

  const fetchTokenFunc = async () => {
    try {
      const token = await fetchToken();
      setToken((prev) => token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTokenFunc();
    // fetchOverals();

    // setInterval(fetchOverals, 3000);
    return () => {
      // clearInterval(fetchOverals);
    };
  }, []);

  let tokenObjectDevices = [];
  if (
    Object.keys(token).length !== 0 &&
    token.user_id !== undefined &&
    token.user_id !== ""
  ) {
    let userIds = token.user_id.split(",");
    let osIds = token.os_id.split(",");
    for (let i = 0; i < userIds.length; i++) {
      let platform = "win";
      let userId = userIds[i];
      let osId = osIds[i];
      if (osId.length === 36) {
        platform = "ios";
      } else if (osId.length === 16) {
        platform = "android";
      }
      let device = {
        tokenId: token.id,
        userId,
        osId,
        platform,
      };
      tokenObjectDevices.push(device);
    }
  }

  return (
    <>
      <div
        className="relative mt-12 flex-col justify-center items-center
    m-10 p-5
    bg-black"
      >
        <div className="absolute border-t-1 border-l-1 border-white h-1 w-1 left-3 top-3"></div>
        <div className="absolute border-t-1 border-r-1 border-white h-1 w-1 right-3 top-3"></div>
        <div className="absolute border-b-1 border-l-1 border-white h-1 w-1 left-3 bottom-3"></div>
        <div className="absolute border-b-1 border-r-1 border-white h-1 w-1 right-3 bottom-3"></div>
        <Header category={messages.zone} title={messages.devices} />
        {!isEmpty(token) ? (
          <>
            {tokenObjectDevices.length > 0 ? (
              <div
                className="flex flex-col gap-2 justify-start
      my-10 mx-8"
              >
                {tokenObjectDevices.map((device, index) => {
                  return (
                    <div
                      key={device.userId}
                      className="flex justify-between items-center
            bg-[#1ffea9] text-black gap-5 border-1 border-transparent
            hover:bg-transparent hover:text-[#1ffea9]
            hover:border-1 hover:border-[#1ffea9]  
            font-body md:w-2/3 p-2"
                    >
                      <div className="flex items-center gap-5 ">
                        {device.platform === "android" ? (
                          <BsAndroid className="text-xl" />
                        ) : device.platform === "ios" ? (
                          <BsApple className="text-xl" />
                        ) : (
                          <BsWindows className="text-xl" />
                        )}
                        <div className="flex flex-col gap-1">
                          <span className="text-[8px] sm:text-sm">
                            {device.userId}
                          </span>
                          <span className="text-[8px] sm:text-[11px]">
                            {device.osId}
                          </span>
                        </div>
                      </div>

                      <button
                        onClick={() => {
                          setQuestionableAction(() => () => {
                            return deleteUserDevice(index.toString());
                          });
                          setModalOpen(true);
                        }}
                      >
                        <MdDelete className=" place-items-end  me-1" />
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="flex justify-center items-center">
                <p
                  className="text-[#1ffea9] bg-radial-gradient
               border-1 border-[#1ffea9] px-4 py-2"
                >
                  {messages.no_devices}
                </p>
              </div>
            )}
          </>
        ) : (
          <div className="flex justify-center items-center">
            <div className="loader"></div>
          </div>
        )}
      </div>
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="text-center w-56">
          <MdDelete className="mx-auto text-red-500 text-3xl" />
          <div className="mx-auto my-4 w-48">
            <h3 className="text-lg font-black text-[#1ffea9]">
              {messages.confirm}
            </h3>
            <p className="text-sm text-white">
              {messages.delete_device_question}
            </p>
          </div>
          <div className="flex gap-4">
            <button
              className="flex justify-center
               items-center
              bg-[#1ffea9] py-1
               text-black w-full"
              onClick={async () => {
                try {
                  setModalPrimaryButtonProgressing(true);
                  const result = await questionableAction();
                  setModalPrimaryButtonProgressing(false);
                  setToken({});
                  fetchTokenFunc();
                } catch (error) {
                  setCurrentToast({
                    isToasted: true,
                    text: error.message,
                    color: "red-500",
                  });
                }

                setModalOpen(false);
              }}
            >
              {modalPrimaryButtonProgressing && (
                <div
                  className="loader-transparent me-2"
                  style={{ height: "20px", width: "20px" }}
                ></div>
              )}
              <span>{messages.devices_delete_modal_yes}</span>
            </button>
            <button
              className="bg-red-500 py-1 text-black btn btn-light w-full"
              onClick={() => setModalOpen(false)}
            >
              {messages.devices_delete_modal_cancel}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Devices;
