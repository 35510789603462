export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const isAfter = (date) => {
  const today = new Date();
  return today > new Date(date);
};

export const toSectionedToken = (token) => {
  let newToken = "";
  if (token && token.length > 0) {
    for (var i = 0; i < token.length; i++) {
      if (i != 0 && i % 4 == 0) {
        newToken += "-";
      }
      newToken += token.charAt(i);
    }

    return newToken;
  }
  return token;
};

export const toUnSectionedToken = (token) => {
  let newToken = "";
  if (token && token.length > 0) {
    newToken = token.replaceAll("-", "");
    return newToken;
  }
  return token;
};

export const isNumeric = (str) => {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};
